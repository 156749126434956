import $ from "jquery";

$(function () {
    if (localStorage.getItem('noloader')) {
        localStorage.removeItem('noloader');
        $("#content-body").fadeIn("fast");
    } else {
        $("#loader").load("app/loader/index.html");
        setTimeout(() => {
            $("#content-body").show();
            $("#loader").fadeOut();
        }, 3500);
    }
});

import $ from "jquery";

$("#btnApp").click(function () {
    window.location = 'dist/activate/activate.html';
})
$("#btnEmail").click(function () {
    window.location = 'dist/login/login.html';
})

setTimeout(() => {
    let session = localStorage.getItem("currentIdToken");
    if (session) {
        // let isExpired = tokenIsExpired(session);
        // if (!isExpired) {
        //     if(window.location.href.includes('login') || window.location.href.includes('change-password')) {
        //         window.location = '../home/experience-new.html';
        //     } else {
        //         window.location = 'dist/home/experience-new.html';
        //     }
        // }
        if(window.location.href.includes('change-password')) {
            return;
        }
        if(window.location.href.includes('login') || window.location.href.includes('change-password')) {
            window.location = '../home/experience-new.html';
        } else {
            window.location = 'dist/home/experience-new.html';
        }
    }
}, 0);

// es necesario acomodar el enter para el input

// En un objeto, usando jQuery agrupamos todos los objetos a los que le hemos asignado la clase de
// selectable. Para iterar más fácil, lo convertimos en un array. Después establecemos el índice
// máximo del array para no encontrarnos con valores indefinidos. 

const selectablesObject = $(".selectable");
const selectablesArray = $.map(selectablesObject, function (value, index) { return [value]; });
const upperLimit = selectablesArray.length - 1;
selectablesArray[0].focus();
// console.log(selectablesArray.indexOf(document.activeElement));

// Al momento de usar la tecla central o enter, si estamos en un elemento de tipo input, abrirá
// el teclado virtual (a eso se refiere event.detail.visibility). Si está en otro elemento, hará
// la función genérica click, lo cual accionará botones y demás.

/**
 * Accion para la tecla enter
 */
function enterKey() {
    if (document.activeElement && document.activeElement.tagName === "INPUT") {
        downKey();
    } else {
        $(this).click;
        return;
    }
    if ($(this).tagName === "input") {
        event.detail.visibility = true;
        // console.log("enter key has been pressed on an input");
    } else {
        $(this).click;
        // console.log("enter key has been pressed on something else");
    }
}

// Al presionar arriba o izquierda, haremos focus en el elemento anterior con la clase de selectable,
// y usando derecha o abajo, en el elemento siguiente. Si nos intentamos devolver en el primer elemento
// nos encontraremos con el último, y al intentar avanzar más allá del último, volveremos al primero.

/**
 * Accion para la flecha de arriba
 */
function upKey() {
    const newIndex = selectablesArray.indexOf(document.activeElement) - 1;
    if (newIndex < 0) {
        selectablesArray[upperLimit].focus();
    } else if (0 <= newIndex <= upperLimit) {
        selectablesArray[newIndex].focus();
    }
}

/**
 * Accion para la flecha de abajo
 */
function downKey() {
    const newIndex = selectablesArray.indexOf(document.activeElement) + 1;
    // console.log("down key has been pressed");
    if (newIndex > upperLimit) {
        selectablesArray[0].focus();
    } else if (0 <= newIndex <= upperLimit) {
        selectablesArray[newIndex].focus();
    }
}

/**
 * Accion para la flecha de la izquierda
 */
function leftKey() {
    if (document.activeElement && document.activeElement.tagName === "INPUT") {
        return;
    }
    const newIndex = selectablesArray.indexOf(document.activeElement) - 1;
    // console.log("left key has been pressed");
    if (newIndex < 0) {
        selectablesArray[upperLimit].focus();
    } else if (0 <= newIndex <= upperLimit) {
        selectablesArray[newIndex].focus();
    }
}

/**
 * Accion para la flecha de la derecha
 */
function rightKey() {
    if (document.activeElement && document.activeElement.tagName === "INPUT") {
        if (document.activeElement.id === "signupPassInput" && document.activeElement.value.length === document.activeElement.selectionStart) { 
            showHideSignUpPasswordHandler();
        }
        if (document.activeElement.id === "newPasswordInput" && document.activeElement.value.length === document.activeElement.selectionStart) { 
            showHidePasswordEye1Handler();
        }
        if (document.activeElement.id === "repeatPasswordInput" && document.activeElement.value.length === document.activeElement.selectionStart) { 
            showHidePasswordEye2Handler();
        }
        return;
    }
    const newIndex = selectablesArray.indexOf(document.activeElement) + 1;
    // console.log("right key has been pressed");
    if (newIndex > upperLimit) {
        selectablesArray[0].focus();
    } else if (0 <= newIndex <= upperLimit) {
        selectablesArray[newIndex].focus();
    }
}

function tokenIsExpired(token) {
    if (!token) {
      return false;
    }
    let isExpired = false;
    const tokenPayload = token.split('.')[1];
    const tokenPayloadObj = JSON.parse(window.atob(tokenPayload));
    if (tokenPayloadObj.exp) {
      const currentTime = Date.now()/1000;
      isExpired = currentTime > tokenPayloadObj.exp;
    }
    return isExpired;
  }
/** @module FirebaseFunctions */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firestoreUrl = "https://firestore.googleapis.com/v1/projects/harmonie-3a/databases/(default)/documents";
let currentIdToken = localStorage.getItem("currentIdToken");
const currentSessionId = localStorage.getItem("sessionId");
let hasSubscription = false;
let db;

const firebaseConfig = {
  apiKey: "AIzaSyB49hpvwGJS_Y_DIftEY3Fb4W_vu32evSY",
  authDomain: "harmonie-3a.firebaseapp.com",
  databaseURL: "https://harmonie-3a.firebaseio.com",
  projectId: "harmonie-3a",
  storageBucket: "harmonie-3a.appspot.com",
  messagingSenderId: "263900809937",
  appId: "1:263900809937:web:4479a7413b5e85c8",
  measurementId: "G-PQN0V9LZXD",
  persistance: false,
};

/**
 * Punto de entrada del modulo
 */
function init() {
  try {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    db = firebase.firestore();
    db.enablePersistence().catch((err) => {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
    });
  }
    firebase.auth().onAuthStateChanged((user) => {
      if(user) {
        user.getIdToken().then((idToken) => {
          localStorage.setItem("currentIdToken", idToken);
          currentIdToken = idToken;
          if(window.location.href.includes('change-password')) {
            return;
          }
          if(window.location.href.includes('login') || window.location.href.includes('change-password')) {
            window.location = '../home/experience-new.html';
          } else if(window.location.href.includes('index')) {
              window.location = 'dist/home/experience-new.html';
          }
        });
      }
    });
  } catch (error) {
    console.error(error);
  }
}

init();

/**
 * Elimina espacios de los campos de email y contraseña y llama al servicio de login de Firebase
 * @param {string} email 
 * @param {string} pass
 * @param {boolean} welcome
 */
function logInAttempt(email, pass, welcome) {
  var emailTrimmed = email.val().trim();
  var passTrimmed = pass.val().trim();
  return postLogIn(emailTrimmed, passTrimmed, welcome);
}

/**
 * Ejecuta el inicio de sesión con email y contraseña de firebase via SDK
 * @param {string} email 
 * @param {string} pass
 * @param {boolean} welcome
 */
function postLogIn(email, pass, welcome) {
  try {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, pass)
      .then(function (result) {
        loginProcess(result, welcome);
      })
      ["catch"](function (error) {
        manageLoginError(error);
      });
  } catch (err) {
    showError();
  }
}

function loginProcess(result, welcome) {
  localStorage.removeItem("allExperiences");
  localStorage.removeItem("allMeditations");
  localStorage.removeItem("allBreathingGuides");
  localStorage.removeItem("allGratitudes");
  localStorage.removeItem("allRituals");
  localStorage.setItem("currentIdToken", result.user.ya);
  localStorage.setItem("currentEmail", result.user.email);
  localStorage.setItem("localId", result.user.uid);
  if (welcome) {
    window.location = "../welcome/welcome.html";
  } else {
    window.location = "../home/experience-new.html";
  }
}

/**
 * Ejecuta la creación de una nueva cuenta en Firebase via Api Request
 * @param {string} email 
 * @param {string} pass
 * @param {string} name
 */
function signUpAttempt(email, pass, name) {
  var emailTrimmed = email.val().trim();
  var passTrimmed = pass.val().trim();
  var nameTrimmed = name.val().trim();
  var resp = $.post(
    "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyB49hpvwGJS_Y_DIftEY3Fb4W_vu32evSY",
    {
      email: emailTrimmed,
      password: passTrimmed,
      returnSecureToken: true,
    }
  ).then(function (result) {
    if (result && result.localId) {
      localStorage.setItem("localId", result.localId);
      const code = generateAlphaNumCode(10);
      saveUser({
        apple: false,
        email: result.email,
        facebook: false,
        google: false,
        id: result.localId,
        name: nameTrimmed,
        picture: "",
        language: "en",
        playlist: [],
        // actualmente por falta de permisos en Firebase no se pude modificar la coleccion users, pudiendo marcar false esta propiedad lo cual seria lo correcto
        emailVerified: true,
        // emailVerified: false,
        welcomeScreen: true,
        verificationCode: code
      })
        .then(function (created) {
          if (created) {
            var resp = $.post(
              "https://us-central1-harmonie-3a.cloudfunctions.net/sendEmail",
              {
                data: {
                  to: result.email,
                  templateId: "d-ac7215bbcbd249bfb698e065009c5abb",
                  actionLink: "https://harmonie-tv.web.app/dist/verify-email/verify.html?verificationCode=" + code + "&uid=" + result.localId + "&email=" + encodeURIComponent(result.email)
                  // probando en local
                  // actionLink: "http://localhost:5500/app/dist/verify-email/verify.html?verificationCode=" + code + "&uid=" + result.localId + "&email=" + encodeURIComponent(result.email)
                }
              }
            ).then(function (r) {
              if(r && r.data && r.data.ok) {
                if(alertHarmonie) {
                  alertHarmonie(lang.accountCreated);
                }
                $("#signupModal").fadeOut();
                signupPanel.style.opacity = 0;
                $("#checkInboxModal").show();
                $("#continueButton").focus();
              } else {
                showError();
              }
            })
            ["catch"](function (err) {
              showError();
              closeModal();
            });
          }
        })
        ["catch"](function (err) {
          showError();
          closeModal();
        });
    }
  })
  .catch(function (err) {
    if (err.responseJSON.error.message == 'EMAIL_EXISTS'){
      $("#errorEmailMessage").show();
      $("#signupEmailInput").css("border-bottom-color", "#EB5757");
      $("#signupEmailInput").focus();
      $("#errorEmailMessage").html(lang.emailExists);
      return;
    } 
    if (err.responseJSON.error.message.includes("WEAK_PASSWORD")){
      $("#errorPasswordMessage").show();
      $("#signupPassInput").css("border-bottom-color", "#EB5757");
      $("#signupPassInput").focus();
      $("#errorPasswordMessage").html(lang.passwordMinLength);
      return;
    } 
    showError();
  });
}

/**
 * Ejecuta el reset de password en Firebase via Api Request
 * @param {string} email 
 */
function resetPass(email) {
  var emailTrimmed = email;
  $("#userForgottenPass").html(emailTrimmed);
  var resp = $.post(
    "https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyB49hpvwGJS_Y_DIftEY3Fb4W_vu32evSY",
    {
      requestType: "PASSWORD_RESET",
      email: emailTrimmed,
    }
  );
}

/**
 * Ejecuta el reset de password de la pantalla de inicio de la aplicacion via Api Request en Firebase 
 * @param {string} email 
 */
function resetPassword(email) {
  var resp = $.post(
    "https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyB49hpvwGJS_Y_DIftEY3Fb4W_vu32evSY",
    {
      requestType: "PASSWORD_RESET",
      email,
    }
  ).then(function (r) {
    $("#signupModal").fadeOut();
    $("#checkInboxModal").show();
    $("#continueButton").focus();
  })
  ["catch"](function (err) {
    console.log(err);
    showError();
    closeModal();
  });
}

/**
 * Ejecuta el cambio de password por uno nuevo via Api Request en Firebase 
 * @param {string} oldPassword
 * @param {string} newPassword
 */
function changePassword(oldPassword, newPassword) {
  var resp = $.post(
    "https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyB49hpvwGJS_Y_DIftEY3Fb4W_vu32evSY",
    {
      idToken: currentIdToken,
      password: newPassword,
      returnSecureToken: true,
    }
  );
  setTimeout(function () {
    window.location = "experience-new.html";
  }, 500);
}

/**
 * Obtiene las experiencias de determinado tipo
 * @param {string} type
 */
function getExperiencesByTypeAsync(type) {
  return new Promise(function (res, rej) {
    getExperiences().then(function (result) {
      var filtered = result.filter(function (experience) {
        return (
          experience.experienceType.findIndex(function (value) {
            return value == type;
          }) != -1 && !experience.deleted
        );
      });
      res(filtered);
    });
  });
}

/**
 * Obtiene los documentos de la colección dada
 * @param {string} collectionName
 */
function getCollectionData(collectionName) {
  if(collectionName === "Experiences") {
    if (localStorage.getItem("allExperiences")) {
      let allExps = JSON.parse(localStorage.getItem("allExperiences"));
      return new Promise((resolve) => {
        resolve(allExps);
      });
    }
    return getExperiencesNew();
  }
  if(collectionName === "Meditations") {
    if (localStorage.getItem("allMeditations")) {
      let allMeds = JSON.parse(localStorage.getItem("allMeditations"));
      return new Promise((resolve) => {
        resolve(allMeds);
      });
    }
    return getMeditationsNew();
  }
  if(collectionName === "BreathingGuides") {
    if (localStorage.getItem("allBreathingGuides")) {
      let allGuides = JSON.parse(localStorage.getItem("allBreathingGuides"));
      return new Promise((resolve) => {
        resolve(allGuides);
      });
    }
    return getBreathingsNew();
  }
  // if(collectionName === "Gratitudes") {
  //   if (localStorage.getItem("allGratitudes")) {
  //     let allGratitudes = JSON.parse(localStorage.getItem("allGratitudes"));
  //     return new Promise((resolve) => {
  //       resolve(allGratitudes);
  //     });
  //   }
  //   return getGratitudes();
  // }
  return db
    .collection(collectionName)
    .get()
    .then(function (querySnapshot) {
      const data = [];
      querySnapshot.forEach(function (doc) {
        if (doc.data().deleted) {
          return;
        }
        data.push(Object.assign({ id: doc.id }, doc.data()));
      });
      return data;
    })
    ["catch"](function (error) {
      console.error(error);
      return null;
    });
}

/**
 * Obtiene la data del usuario que esta logueado actualmente
 */
function getUser() {
  return new Promise(function (res, rej) {
    try {
      var uid = localStorage.getItem("localId");
      db.collection("Users")
        .where("id", "==", uid)
        .get()
        .then(function (querySnapshot) {
          var data = [];
          querySnapshot.forEach(function (doc) {
            data.push(doc.data()); // doc.data() is never undefined for query doc snapshots
          });
          res(data && data.length > 0 ? data[0] : null);
        })
        ["catch"](function (error) {
          res(null);
        });
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Guarda la data proporcionada del usuario que esta logueado actualmente
 * @param {object} data
 */
function saveUser(data) {
  return new Promise(function (res, rej) {
    try {
      var uid = localStorage.getItem("localId");
      db.collection("Users")
        .doc(uid)
        .set(data, {
          merge: true,
        })
        .then(function () {
          res(true);
        })
        ["catch"](function (error) {
          res(false);
        });
    } catch (error) {
      showError();
      console.log("error saving user:", error);
      res(null);
    }
  });
}

/**
 * Guarda la data proporcionada de la sesión del usuario
 * @param {string} code
 */
function saveActivationCode(code) {
  return db
    .collection("Sessions")
    .doc(code)
    .set({
      token: "",
      id: "",
      playing: "",
    })
    .then(function (_) {
      return true; // res(docRef.id);
    })
    ["catch"](function (error) {
      return false;
    });
}

/**
 * Realiza el parseo recursivo del objeto proporcionado
 * @param {object} object
 */
function parseFirebaseObject(object) {
  var result = {};

  for (var key in object) {
    switch (key) {
      case "integerValue":
        return parseInt(object[key]);

      case "stringValue":
        return object[key];

      case "booleanValue":
        return object[key];

      case "nullValue":
        return null;

      case "values":
        return Object.values(parseFirebaseObject(object[key]));

      case "arrayValue":
        return Object.keys(object[key]).length === 0
          ? []
          : parseFirebaseObject(object[key]);

      case "mapValue":
        return parseFirebaseObject(object[key].fields);

      default:
        // if que llama
        result[key] =
          _typeof(object[key]) == "object"
            ? parseFirebaseObject(object[key])
            : object[key];
        break;
    }
  }
  return result;
}

/**
 * Valida el string proporcionado para determinar si es un email válido o no
 * @param {string} email
 * @returns {boolean}
 */
function validateEmail(email) {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)) {
    return true;
  }
  return false;
}

/**
 * Muestra una alerta con mensaje de error
 */
function showError() {
  if(alertHarmonie) {
    alertHarmonie(lang.error);
  }
}

/**
 * Muestra una alerta con mensaje de errores para el inicio de sesión
 * @param {object} err
 */
function manageLoginError(err) {
  $("#emailInput").css("border-bottom-color", "#FFFFFF");
  $("#passInput").css("border-bottom-color", "#FFFFFF");
  if (err.code && err.code.includes("auth/user-not-found")){
    $("#errorEmailMessage").show();
    $("#emailInput").css("border-bottom-color", "#EB5757");
    $("#emailInput").focus();
    $("#errorEmailMessage").html(lang.errorEmailMessage4);
    return;
  } 
  if (err.code && err.code.includes("auth/wrong-password")){
    $("#errorPasswordMessage").show();
    $("#passInput").css("border-bottom-color", "#EB5757");
    $("#passInput").focus();
    $("#errorPasswordMessage").html(lang.passwordInvalid);
    return;
  } 
  showError();
}

/**
 * Muestra una alerta con mensaje de errores para el cambio de contraseña
 * @param {object} err
 */
function manageChangePasswordErrors(err) {
  $("#newPasswordInput").css("border-bottom-color", "#FFFFFF");
  $("#repeatPasswordInput").css("border-bottom-color", "#FFFFFF");
  if (err.responseJSON.error.message == "EXPIRED_OOB_CODE"){
    if(alertHarmonie) {
      alertHarmonie(lang.resetExpiredCode);
    }
    return;
  } 
  if (err.responseJSON.error.message == "INVALID_OOB_CODE"){
    if(alertHarmonie) {
      alertHarmonie(lang.resetInvalidLink);
    }
    return;
  } 
  if (err.responseJSON.error.message.includes("TOO_MANY_ATTEMPTS_TRY_LATER")){
    if(alertHarmonie) {
      alertHarmonie(lang.tooManyAttempts);
    }
    return;
  } 
  showError();
}

/**
 * Genera un código alfanumérico aleatorio de la longitud dada
 * @param {number} len
 * @returns {string}
 */
function generateAlphaNumCode(len) {
  var code;
  for (var a = ""; (r = Math.random() * 42), a.length < len; ) {
    code = a += r < 10 || r > 16 ? String.fromCharCode((48 + r) | 0) : "";
  }
  const regex = /@/;
  return code.replace(regex, "A");
}

/**
 * Verifica si el código de activación proporcionado es el correcto para la activación de la cuenta de usuario
 * @param {string} email
 * @param {string} code
 * @param {string} uid
 */
function verifyEmailCode(code, uid) {
  return new Promise(async function (res, rej) {
    const result = await verifyCustomToken(code);
    if(!result) {
      res(null);
      return;
    }
    try {
      db.collection("Users")
      .where("id", "==", uid)
      .get()
      .then(function (querySnapshot) {
        let data = [];
        querySnapshot.forEach(function (doc) {
          data.push(doc.data());
        });
        if (data && data.length > 0) {
          user = data[0];
          const payload = {
            emailVerified: true,
          };
          db.collection("Users")
          .doc(uid)
          .set(payload, {
            merge: true,
          })
          .then(function () {
            res(true);
          })
          ["catch"](function (error) {
            console.log('Error al guardar el documento');
            res(null);
          });
        } else {
          console.log('No se encontro el uid de usuario', uid);
          res(null);
        }
      })
      ["catch"](function (error) {
        console.log(error);
        res(null);
      });
    } catch (error) {
      console.log(error);
      res(null);
    }
  });
}

/**
 * Obtiene la data de un usuario dado su email
 * @param {string} email
 */
function getUserByEmail(email) {
  return new Promise(function (res, rej) {
    try {
      db.collection("Users")
      .where("email", "==", email)
      .get()
      .then(function (querySnapshot) {
        let data = [];
        querySnapshot.forEach(function (doc) {
          data.push(doc.data());
        });
        res(data && data.length > 0 ? data[0] : null);
      })
      ["catch"](function (error) {
        res(null);
      });
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Llama al servicio de Firebase via Api Request para solicitar envio de email para reseteo de contraseña
 * @param {string} email
 */
function sendEmailForResetPassword(email) {
  let lang = 'en';
  const language= localStorage.getItem('lang');
  if(language === 'es') {
    lang = 'es';
  }
  var resp = $.post(
    "https://us-central1-harmonie-3a.cloudfunctions.net/send_forgot_password_email",
    {
      email,
      language: lang,
    }
  ).then(function (r) {
    $("#signupModal").fadeOut();
    $("#checkInboxModal").show();
    $("#continueButton").focus();
  })
  ["catch"](function (err) {
    console.log(err);
    showError();
    closeModal();
  });
}

/**
 * Llama al servicio de Firebase via Api Request para solicitar un cambio de password en base al codigo obtenido desde el email recibido
 * @param {string} newPassword
 * @param {string} oobCode
 */
function newChangePassword(newPassword, oobCode) {
  var resp = $.post(
    "https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=AIzaSyB49hpvwGJS_Y_DIftEY3Fb4W_vu32evSY",
    {
      oobCode,
      newPassword,
    }
  ).then(function (result) {
    if(alertHarmonie) {
      alertHarmonie(lang.resetSuccess);
    }
    $("#newPasswordInput").val("");
    $("#repeatPasswordInput").val("");
    })
  ["catch"](function (error) {
    console.log(error);
    manageChangePasswordErrors(error);
  });
}

/**
 * Realiza el cierre de sesion del usuario en Firebase via SDK
 * @param {string} redirectPath - url a la cual ser redirigido luego del cierre de sesión
 */
function signOutFirebase(redirectPath) {
  firebase
    .auth()
    .signOut()
    .then(() => {
      removeStorageKeys();
      console.log("Session successfully deleted!");
      window.location = redirectPath;
    });
}

function removeStorageKeys() {
  localStorage.removeItem("currentIdToken");
  localStorage.removeItem("currentEmail");
  localStorage.removeItem("localId");
  localStorage.removeItem("loadedUser");
  localStorage.removeItem("sessionId");
  localStorage.removeItem("allExperiences");
  localStorage.removeItem("allMeditations");
  localStorage.removeItem("allBreathingGuides");
  localStorage.removeItem("allGratitudes");
  localStorage.removeItem("allRituals");
  localStorage.removeItem("subscriptions");
  sessionStorage.removeItem("current-ritual");
}

/**
 * Eliminar el documento en Firebase de la sesión actual del usuario
 * @param {string} redirectPath - url a la cual ser redirigido luego del cierre de sesión
 */
function deleteSession(redirectPath) {
  const session = localStorage.getItem("sessionId");
  if (session) {
    try {
      db.collection("Sessions")
        .doc(session)
        .delete()
        .then(() => {
          signOutFirebase(redirectPath);
        })
        ["catch"](function (error) {
          console.log('Error deleting session', error);
        });
    } catch (error) {
      console.log('Error deleting session', error);
    }
  } else {
    signOutFirebase(redirectPath);
  }
}

/**
 * Obtener los playlists del usuario de la nueva colección de Playlists
 * @param {array} experiencesList
 */
function getPlaylistsFromUser(experiencesList) {
  return new Promise(function (res, rej) {
    try {
      let uid = localStorage.getItem("localId");
      if (uid && experiencesList && experiencesList.length) {
        db.collection(`Users/${uid}/playlists`)
          .get()
          .then(function (querySnapshot) {
            let data = null;
            let playlistsWithExperiences = [];
            querySnapshot.forEach(function (doc) {
              if (doc.exists) {
                data = doc.data();
                const playlist = {name: doc.id, experiences: [], datetimeNumber: Date.now()};
                if (data && data.ids && data.ids.length) {
                  data.ids.forEach(function(id) {
                    let exp = experiencesList.find(function(e){return e.id == id});
                    if (exp) {
                      playlist.experiences.push(exp);
                    }
                  });
                }
                playlistsWithExperiences.push(playlist);
              }
            });
            res(playlistsWithExperiences);
          })
          ["catch"](function (error) {
            res(null);
          });
      } else {
        res(null);
      }
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Crear una playlist vacia
 * @param {array} playlistList
 */
function createOrUpdatePlaylist(playlist) {
  return new Promise(function (res, rej) {
    try {
      let uid = localStorage.getItem("localId");
      if(uid) {
        db.collection(`Users/${uid}/playlists`)
          .doc(playlist.name)
          .set({ ids: playlist.experiences.map((e)=> e.id) }, {
            merge: true,
          })
          .then(function (resp) {
            res(true);
          }).catch(function (error) {
            res(null);
          });
      } else {
        res(null);
      }
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Crear una playlist vacia
 * @param {string} playlistName
 */
function deletePlaylistDoc(playlistName) {
  return new Promise(function (res, rej) {
    try {
      let uid = localStorage.getItem("localId");
      if(uid) {
        db.collection(`Users/${uid}/playlists`)
          .doc(playlistName)
          .delete()
          .then(function (resp) {
            res(true);
          }).catch(function (error) {
            res(null);
          });
      } else {
        res(null);
      }
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Cambiar el nombre a una playlist
 * @param {string} oldName
 * @param {string} newName
 * @param {array} playlist
 */
function changePlaylistNameDoc(oldName, newName, playlist) {
  return new Promise(function (res, rej) {
    try {
      let uid = localStorage.getItem("localId");
      if(uid) {
        createOrUpdatePlaylist({
          experiences: playlist.experiences,
          name: newName,
        })
        .then((resp) => {
          if(resp) {
            deletePlaylistDoc(oldName)
            .then(() => {
              res(true);
            })
          } else {
            rej(null);
          }
        })
        ["catch"]((err) => {
          res(null);
        });
      } else {
        res(null);
      }
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Obtener los favoritos de meditaciones del usuario de la colección de Favorites
 * @param {array} meditationsList
 */
function getFavoriteMeditationsFromUser(meditationsList) {
  return new Promise(function (res, rej) {
    try {
      let uid = localStorage.getItem("localId");
      if (uid && meditationsList && meditationsList.length) {
        db.collection(`Users/${uid}/favorites`)
        .get()
        .then(function (querySnapshot) {
          let favorites = [];
          querySnapshot.forEach(function (doc) {
            if (doc.exists) {
              let fav = {id: doc.id, datetimeNumber: Date.now()};
              let med = meditationsList.find(function(m){return m.id == doc.id});
              if (med) {
                fav = {...fav, ...med};
                favorites.push(fav);
              }
            }
          });
          res(favorites);
        })
        ["catch"](function (error) {
          res(null);
        });
      } else {
        res(null);
      }
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Obtener los favoritos de meditaciones del usuario de la colección de Favorites
 */
function getFavoriteMeditationsOnlyIds() {
  return new Promise(function (res, rej) {
    try {
      let uid = localStorage.getItem("localId");
      if (uid) {
        db.collection(`Users/${uid}/favorites`)
        .get()
        .then(function (querySnapshot) {
          let favoritesIds = [];
          querySnapshot.forEach(function (doc) {
            if (doc.exists) {
              favoritesIds.push(doc.id);
            }
          });
          res(favoritesIds);
        })
        ["catch"](function (error) {
          res(null);
        });
      } else {
        res(null);
      }
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Agregar meditación como favorita
 * @param {string} favoriteId
 */
function addFavoriteDoc(favoriteId) {
  return new Promise(function (res, rej) {
    try {
      let uid = localStorage.getItem("localId");
      if(uid) {
        db.collection(`Users/${uid}/favorites`)
          .doc(favoriteId)
          .set({}, {
            merge: true,
          })
          .then(function (resp) {
            res(true);
          }).catch(function (error) {
            res(null);
          });
      } else {
        res(null);
      }
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Eliminar meditación de favoritos
 * @param {string} favoriteId
 */
function deleteFavoriteDoc(favoriteId) {
  return new Promise(function (res, rej) {
    try {
      let uid = localStorage.getItem("localId");
      if(uid) {
        db.collection(`Users/${uid}/favorites`)
          .doc(favoriteId)
          .delete()
          .then(function (resp) {
            res(true);
          }).catch(function (error) {
            res(null);
          });
      } else {
        res(null);
      }
    } catch (error) {
      res(null);
    }
  });
}

/**
 * Vigilar corte de conexion de red y redirigir a vista de problemas de conexión
 */
function watchOffline() {
  Offline.options = {
      checkOnLoad: true,
      interceptRequests: true,
      reconnect: {
      initialDelay: 10,
      delay: 15
      },
      requests: false,
      game: false,
      checks: {
        xhr: {url: 'https://www.wikipedia.org/portal/wikipedia.org/assets/img/Wikipedia-logo-v2@1.5x.png'}
      }
  }
  Offline.on("down", function () {
      window.location = '../network-error/network-error.html';
  });
  Offline.on("confirmed-down", function () {
      window.location = '../network-error/network-error.html';
  });
  window.addEventListener('offline', (e) => { 
    setTimeout(() => {
      window.location = '../network-error/network-error.html';
    }, 1000);
  });
}

/**
 * Verifica si la resolucion del TV es de 720
 */
function is720pHeight() {
  return window.innerHeight < 900;
}

/**
 * Verifica que el usuario tenga una subscripción activa
 */
function validateSubscription(revalidate = false) {
  if (localStorage.getItem("subscriptions") && !revalidate) {
    let subs = JSON.parse(localStorage.getItem("subscriptions"));
    hasSubscription = subs && subs.length ? true : false;
    return new Promise(function (resolve) {
      resolve(true);
    });
  }
  $.ajaxSetup({
    headers:{
      'X-FireIDToken': currentIdToken
      }
  });
  return $.post("https://us-central1-harmonie-3a.cloudfunctions.net/subscription_status",{})
    .then((subs) => {
      hasSubscription = subs && subs.length ? true : false;
      localStorage.setItem("subscriptions", JSON.stringify(subs));
    })
    ["catch"]((err) => {
      hasSubscription = false;
  });
}

/**
 * Determina si el navegador actual esta corriendo sobre un dispositivo Apple
 */
function isApplePlatform() {
  const vendor = navigator.vendor;
  const platform = navigator.platform;
  console.log('vendor', vendor);
  console.log('platform', platform);
  if ((/Apple Computer/.test(vendor) || /Apple/i.test(vendor) || /Apple/i.test(platform) || /Mac/i.test(platform))) {
    return true;
  }
  return false;
}

/**
 * Verifica que el usuario haya iniciado sesión
 */
function validateSession() {
  const session = localStorage.getItem("currentIdToken");
  if (!session) {
      window.location = '../../index.html';
      return;
  }
  // checkTokenExpiration(session);
}

/**
 * @description Verificar si el token esta expirado
 */
async function checkTokenExpiration(token) {
  if (!token) {
    return;
  }
  let isExpired = tokenIsExpired(token);
  if (isExpired) {
    localStorage.removeItem("currentIdToken");
    window.location = '../../index.html';
  }
}

function tokenIsExpired(token) {
  if (!token) {
    return false;
  }
  let isExpired = false;
  const tokenPayload = token.split('.')[1];
  const tokenPayloadObj = JSON.parse(window.atob(tokenPayload));
  if (tokenPayloadObj.exp) {
    const currentTime = Date.now()/1000;
    isExpired = currentTime > tokenPayloadObj.exp;
  }
  return isExpired;
}

/**
 * Obtiene las experiencias a las que tiene acceso el usuario actual
 */
function getExperiencesNew() {
  $.ajaxSetup({
    headers:{
      'X-FireIDToken': currentIdToken
      }
  });
  return $.post("https://us-central1-harmonie-3a.cloudfunctions.net/get_experiences",{})
    .then(function (r) {
      if (r && r.data && r.data.length) {
        let newExperiencesList = r.data;
        localStorage.setItem("allExperiences", JSON.stringify(newExperiencesList));
        return r.data;
      }
      return [];
    })
    ["catch"](function (err) {
      console.error(err);
      return [];
  });
}

/**
 * Obtiene las experiencias a las que tiene acceso el usuario actual
 */
function getMeditationsNew() {
  $.ajaxSetup({
    headers:{
      'X-FireIDToken': currentIdToken
      }
  });
  return $.post("https://us-central1-harmonie-3a.cloudfunctions.net/get_meditations",{})
    .then(function (r) {
      if (r && r.data && r.data.length) {
        let meditationsList = r.data;
        localStorage.setItem("allMeditations", JSON.stringify(meditationsList));
        return r.data;
      }
      return [];
    })
    ["catch"](function (err) {
      console.error(err);
      return [];
  });
}

/**
 * Obtiene las respiraciones guiadas a las que tiene acceso el usuario actual
 */
function getBreathingsNew() {
  $.ajaxSetup({
    headers:{
      'X-FireIDToken': currentIdToken
      }
  });
  return $.post("https://us-central1-harmonie-3a.cloudfunctions.net/get_breathings",{})
    .then(function (r) {
      if (r && r.data && r.data.length) {
        let breathingGuidesList = r.data;
        localStorage.setItem("allBreathingGuides", JSON.stringify(breathingGuidesList));
        return r.data;
      }
      return [];
    })
    ["catch"](function (err) {
      console.error(err);
      return [];
  });
}


/**
 * Obtiene las gratitudes a las que tiene acceso el usuario actual
 */
function getGratitudes() {
  $.ajaxSetup({
    headers:{
      'X-FireIDToken': currentIdToken
      }
  });
  return $.post("https://us-central1-harmonie-3a.cloudfunctions.net/get_gratitudes",{})
    .then(function (r) {
      if (r && r.data && r.data.length) {
        let gratitudesList = r.data;
        localStorage.setItem("allGratitudes", JSON.stringify(gratitudesList));
        return r.data;
      }
      return [];
    })
    ["catch"](function (err) {
      console.error(err);
      return [];
  });
}

/**
 * Obtiene la información de los rituales del sueño
 */
function getSleepRituals() {
  if (localStorage.getItem("allRituals")) {
    let allRituals = JSON.parse(localStorage.getItem("allRituals"));
    return new Promise((resolve) => {
      resolve(allRituals);
    });
  }
  return new Promise(async (resolve) => {
    let ritualsList = await getCollectionData('Rituals');
    if(ritualsList && ritualsList.length) {
      ritualsList = ritualsList.sort((a, b) => a.order - b.order);
    }
    ritualsList = await Promise.all(ritualsList.map(async (ritual) => {
      steps = await Promise.all(ritual.steps.map(async (item) => {
          if (item.type === 'experience') {
            let exps = await getCollectionData('Experiences');
            if(exps) {
              let exp = exps.find((e) => e.id === item.id);
              if (exp) {
                return {
                  ...item,
                  item: exp
                }
              }
              return item;
            } 
            return item;
          } else if (item.type === 'meditation') {
            let meds = await getCollectionData('Meditations');
            if(meds) {
              let med = meds.find((m) => m.id === item.id);
              if (med) {
                return {
                  ...item,
                  item: med
                }
              }
              return item;
            }
            return item;
          } else if (item.type === 'breathing') {
            let bgs = await getCollectionData('BreathingGuides');
            if(bgs) {
              let bg = bgs.find((bg) => bg.id === item.id);
              if (bg) {
                return {
                  ...item,
                  item: bg
                }
              }
              return item;
            }
            return item;
          } else if (item.type === 'gratitude') {
            let grs = await getCollectionData('Gratitudes');
            if(grs) {
              let gr = grs.find((gr) => gr.id === item.id);
              if (gr) {
                return {
                  ...item,
                  item: gr
                }
              }
              return item;
            }
            return item;
          } else {
            return item;
          }
        })
      );
      return {
        ...ritual,
        steps
      }
    }));
    localStorage.setItem("allRituals", JSON.stringify(ritualsList));
    resolve(ritualsList);
  });
}

function initPlayer(player, source, collectionItem, hls, type) {
  if (source == null) {
    source = document.createElement("source");
    source.id = "player-video-source";
    player.appendChild(source);
  }

  let adaptativeUrl = '';
  let progressiveUrl = '';
  if(type === 'video') {
    adaptativeUrl = collectionItem.m3u8video;
    progressiveUrl = collectionItem.video720 || collectionItem.video360 || collectionItem.videoUrl || collectionItem.videoUrlTV;
  } else if(type === 'loop') {
    adaptativeUrl = collectionItem.m3u8loop;
    progressiveUrl = collectionItem.loop1080 || collectionItem.loop720 || collectionItem.loop360 || collectionItem.loopTV;
  } else if(type === 'meditation') {
    adaptativeUrl = collectionItem.loopHorizontalUrl;
    progressiveUrl = collectionItem.loop720 || collectionItem.loop360;
  }
  if(!adaptativeUrl && !progressiveUrl) {
    return;
  }
  if (player.canPlayType('application/vnd.apple.mpegurl')) {
    // player.src = adaptativeUrl;
    source.setAttribute("src", adaptativeUrl);
    player.load();
    player.play();
    console.log('playback nativo');
    console.log(player.canPlayType('application/vnd.apple.mpegurl'));
  } else if (Hls.isSupported()) {
    hls = new Hls();

    hls.once(Hls.Events.MEDIA_ATTACHED, function () {
      console.log('video and hls.js are now bound together !');
      player.play();
    });

    hls.once(Hls.Events.MANIFEST_PARSED, function (event, data) {
      console.log('manifest loaded, found ' + data.levels.length + ' quality level',);
      player.play();
    });

    hls.once(Hls.Events.ERROR, function (event, data) {
      const errorType = data.type;
      const errorDetails = data.details;
      const errorFatal = data.fatal;
      console.log('ERROR HLS');
      console.log('errorType', errorType);
      console.log('errorDetails', errorDetails);
      console.log('errorFatal', errorFatal);
    
      if (errorFatal) {
        switch (errorType) {
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log('fatal media error encountered, try to recover');
            hls.recoverMediaError();
            break;
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.error('fatal network error encountered', data);
            // All retries and media options have been exhausted.
            // Immediately trying to restart loading could cause loop loading.
            // Consider modifying loading policies to best fit your asset and network
            // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
            break;
          default:
            // cannot recover
            console.error('fatal error, cannot recover', data);
            hls.destroy();
            break;
        }
      }
    });

    hls.loadSource(adaptativeUrl);
    hls.attachMedia(player);
    console.log('playback con Hls.js');
  } else {
    source.setAttribute("src", progressiveUrl);
    player.load();
    player.play();
    console.log('HLS not supported, playback normal');
  }
}

function loginWithToken(token) {
  return new Promise(function (res, rej) {
    try {
      firebase
      .auth()
      .signInWithCustomToken(token)
      .then(function (result) {
        res(true);
        if(result && result.user) {
          localStorage.setItem("currentIdToken", result.user.ya);
          localStorage.setItem("currentEmail", result.user.email);
          localStorage.setItem("localId", result.user.uid);
        }
      })
      .catch(function(error) {
        showError();
        console.error(error);
        res(null);
      });
    } catch (error) {
      console.error(error);
      res(null);
    }
  });
}

function verifyCustomToken(token) {
  return new Promise(function (res, rej) {
    try {
      firebase
      .auth()
      .signInWithCustomToken(token)
      .then(function (result) {
        res(true);
      })
      .catch(function(error) {
        showError();
        console.error(error);
        res(null);
      });
    } catch (error) {
      console.error(error);
      res(null);
    }
  });
}

function alphaDigitUnderscoreValidation(e) {  // Accept only alpha numerics, no special characters 
  var regex = new RegExp("^[a-zA-Z0-9_- ]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
      return true;
  }

  e.preventDefault();
  return false;
}

/**
 * Ejecuta la creación de una nueva cuenta en Firebase via Google
 * @param {string} email 
 * @param {string} pass
 * @param {string} name
 */
function signUpGoogle(email, pass, name) {
  var provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth()
  .signInWithPopup(provider)
  .then(async (result) => {
    console.log('google result', result);
    const userExists = await getUserByEmail(result.user.email);
    if(userExists) {
      loginProcess(result, false);
    } else {
      localStorage.setItem("localId",  result.user.uid);
      saveUser({
        apple: false,
        email: result.user.email,
        facebook: false,
        google: true,
        id: result.user.uid,
        name: result.user.displayName || result.additionalUserInfo?.profile?.name,
        picture: result.user.photoURL || result.additionalUserInfo?.profile?.picture || '',
        language: language || 'en',
        emailVerified: true,
        welcomeScreen: true,
      })
        .then(function (created) {
          if (created) {
            loginProcess(result, false);
          }
        })
        ["catch"](function (err) {
          console.log('google login error', err);
          showError();
        });
    }
  }).catch((error) => {
    showError();
    console.log('google login error', error);
  });
}

/**
 * Verifica si el código de activación proporcionado es el correcto para la activación de la cuenta de usuario
 * @param {string} email
 * @param {string} code
 * @param {string} uid
 */
function verifyingCodeWebPlayerSignup(email, code, uid) {
  let user;
  return new Promise(function (res, rej) {
    try {
      db.collection("Users")
      .where("id", "==", uid)
      .get()
      .then(function (querySnapshot) {
        let data = [];
        querySnapshot.forEach(function (doc) {
          data.push(doc.data());
        });
        if (data && data.length > 0) {
          user = data[0];
          if (user.verificationCode == code) {
            const payload = {
              emailVerified: true,
            };
            db.collection("Users")
            .doc(uid)
            .set(payload, {
              merge: true,
            })
            .then(function () {
              res(true);
            })
            ["catch"](function (error) {
              res(null);
            });
          } else {
            res(null);
          }
        } else {
          res(null);
        }
      })
      ["catch"](function (error) {
        res(null);
      });
    } catch (error) {
      res(null);
    }
  });
}

/** @module RemoteControl */

// Se le pone un listener a cada una de las teclas que se van a utilizar como input en el control.
// Los números en los casos, simbolizan el keycode único que WebOS le asigna a estas teclas, y le
// asignamos a cada uno una función que será después asignada.

/**
 * Agrega los listeners para los botones en el manejador del evento keydown
 */
document.addEventListener("keydown", function (inEvent) {
  return;
  let keycode;

  if (window.event) {
    keycode = inEvent.keyCode;
  } else if (e.which) {
    keycode = inEvent.which;
  }
  try {
    switch (keycode) {
      case 38:
        upKey();
        try {
          scrollUp(); //Se llama la función creada en el script
        } catch (error) { }
        break;
      case 40:
        downKey();
        try {
          scrollDown(); //Se llama la función creada en el script
        } catch (error) { }
        break;
      case 39:
        rightKey();
        break;
      case 37:
        leftKey();
        break;
      case 13:
        enterKey();
        break;
      case 8:
        deleteKey();
        break;
      case 461: // exit Lg Control Remoto
        backKey();
        break;
    }
  } catch (error) {
    console.error(error);
  }
});

/**
 * Verifica que el DOM esté listo para manipularse y ejecuta la función que se le pase
 * @param {*} fn Función que ejecutará una vez que está listo el DOM
 */
function domReady(fn) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

/**
 * Coloca la primera letra de una cadena en mayuscula y las demás en minuscula
 * @param {*} string cadena a procesar
 */
function capitalize(string) {
  if (string.length > 1) {
    return string[0].toUpperCase() + string.toLowerCase().slice(1);
  } else if (string.length == 1) {
    return string.toUpperCase();
  } else {
    return "";
  }
}

/**
 * Obtiene los parámetros get de una url
 * @param {*} url link completo con los parámetros
 */
function getParams(url) {
  let params = {};
  let parser = document.createElement("a");
  parser.href = url;
  let query = parser.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

/**
 * Remueve elementos hijo de un elemento
 * @param {object} element
 */
function removeAllOptions(element) {
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
}

function backKey() {
  const currentUrl = getCurrentURL();
  if(currentUrl && (currentUrl.includes('experience-new') || currentUrl.includes('index'))) {
    if ((signupModal && signupModal.style.display === 'block') ||
        (document.getElementById('checkInboxModal') && document.getElementById('checkInboxModal').style.display === 'block')) {
        closeModal();
        return;
    }
  } else {
    window.history.back();
  }
}

function getCurrentURL() {
  return window.location.href;
}


import $ from "jquery";

$('#app').append('<div class="alertHarmonie" id="alertHarmonie"></div>');    
$('#alertHarmonie').append('<p id="mensajeHarmonie" class="mensajeHarmonie">Mensaje</p>');    

/**
 * Muestra una alerta o popup con el mensaje dado
 * @param {string} message 
 */
export function alertHarmonie(message) {
    $("#mensajeHarmonie").html(message);
    $("#alertHarmonie").show();
    setTimeout(() => {
        $("#alertHarmonie").hide();
    }, 3000);
}

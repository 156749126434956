import { es } from "./translation-auth";
import { en } from "./translation-auth";

export let lang;
export let language;

/**
 * Obtiene el idioma de la aplicacion en base al parametro guardado en el storage
 */
function getLanguage() {
    (localStorage.getItem('lang') == null) ? setLanguage('en') : false;
    language = localStorage.getItem('lang');
    lang = language == 'es' ? es : en;
}

/**
 * Establece el idioma de la aplicacion en el storage
 */
function setLanguage(lang) {
    localStorage.setItem('lang', lang);
}

/**
 * Traduce los htmls buscando el atributo data-lang y reemplazando por el texto del idioma activo
 */
function translateTemplate() {
    let allDom = document.getElementsByTagName("*");
    for(let i=0; i < allDom.length; i++){
        let elem = allDom[i];
        let key = elem.getAttribute("data-lang");

        if(key != null) {
            elem.innerHTML = lang[key] ;
        }
    }
}

getLanguage();
document.addEventListener("DOMContentLoaded", function() {
    translateTemplate();
});
function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

(function () {
    let langParam = getParameterByName('language');
    if (langParam === 'es') {
        localStorage.setItem('lang', langParam);
    } else {
        localStorage.setItem('lang', 'en');
    }
})();
export const en =  {
    "mainMessage": "Harmonie experiences will achieve the reconciliation of your mind with sleep and peacefulness", 
    "loginHarmonie": "Log in with Harmonie App",
    "loginEmail": "Log in with E-mail",
    "signup": "Sign Up",
    "signupTitle": "Sign Up",
    "name": "Name",
    "password": "Password",
    "cancel": "Cancel",
    "login": "Log in",
    "forgot": "I forgot my password",
    "checkInbox": "Check your inbox",
    "checkInboxInstructions": "We have sent a confirmation link to the email address:",
    "continue": "Continue",
    "resetPasswordTitle": "Reset<br> Password",
    "resetInstructions": "An e-mail will be sent to your e-mail, follow the instructions to recover your password",
    "resetPassword": "Reset password",
    "sendEmail": "We have sent<br> an email",
    "sendTo": "We have sent an email to:",
    "sendInstructions": "with a link of 1 expiration hour to reset your password.",
    "activateTitle": "Activate Harmonie",
    "activateInstructions": "Open your Harmonie App, go to your profile and select the option 'Link with Code', then follow the instructions.",
    "activateScan": "Scan this QR Code",
    "activateCode": "Link with Code",
    "error": "An error has occurred",
    "infoReset": "Password reset directions sent",
    "errorNameMessage": "Please, enter your name",
    "errorEmailMessage1": "Please, enter an email",
    "errorEmailMessage2": "Please, enter a valid email",
    "errorEmailMessage3": "You must verify your email first",
    "errorEmailMessage4": "Email doesn't exist",
    "errorPasswordMessage1": "Please, enter a password",
    "accountCreated": "Account created successfully",
    "emailExists": "Email already exists",
    "passwordMinLength": "Password should be at least 6 characters",
    "passwordNotMeetRequirements": "Password should be at least 8 characters, contain a special character and an upper case letter",
    "passwordInvalid": "The password is incorrect",
    "currentPasswordInvalid": "The current password is incorrect",
    "tooManyAttempts": "Too many attempts. Try later",
    "resetSuccess": "Password reset was succesful",
    "resetYour": "Reset your password:",
    "newPassword": "New password",
    "confirmPassword": "Confirm password",
    "changePassword": "Change password",
    "resetFailed": "Sorry, password reset was unsuccessful. Contact support and try again!",
    "resetExpiredCode": "Sorry, the code has expired. Contact support and try again!",
    "resetInvalidLink": "Sorry, the code is invalid. Contact support and try again!",
    "currentPasswordRequired": "Please, enter current password",
    "newPasswordRequired": "Please, enter new password",
    "confirmPasswordRequired": "Please, confirm new password",
    "matchPasswordRequired": "Passwords doesn't match",
    "verificationSuccess": "Email verification was successful. Enjoy Harmonie!",
    "verificationFailed": "Sorry, email verification was unsuccessful. Contact support and try again!",
    "welcomeTitle": "Welcome to Harmonie!",
    "welcomeText": "Enjoy a Harmonie Life<br> from the comfort of your home.",
    "initExperience": "Start my experience",
    "chooseAvatar": "Choose an avatar",
    "chooseAvatarTitle": "Choose your avatar",
    "harmonieTV": "Harmonie TV",
    "meditation": "Meditation",
    "experiences": "Experiences",
    "avatarSuccess": "Your avatar is ready",
    "signupWarning1": "Don't have an account?",
    "signupWarning2": "Sign up from our mobile app.",
    "tokenExpired": "Token expired. Please, log in again",
    "sleepRelaxLive": "Sleep.Relax.Live",
    "signupGoogle": "Continue with Google",
    "email": "E-mail",
    "password2": "Password",
    "charsStrength": "At least 8 characters",
    "specialStrength": "At least one special character (@#$-/_!%*?&)",
    "shiftStrength": "At least one uppercase character",
}

export const es = {
    "mainMessage": "Las experiencias Harmonie lograr&aacute;n la conciliaci&oacute;n de tu mente con el sue&ntilde;o y la tranquilidad", 
    "loginHarmonie": "Iniciar sesi&oacute;n con  Harmonie App",
    "loginEmail": "Iniciar sesi&oacute;n con E-mail",
    "signup": "Regístrate",
    "signupTitle": "¡Registrarme ahora!",
    "name": "Nombre",
    "password": "Contrase&ntilde;a",
    "cancel": "Cancelar",
    "login": "Iniciar sesi&oacute;n",
    "forgot": "Olvid&eacute; mi contrase&ntilde;a",
    "checkInbox": "Verifica tu bandeja de entrada",
    "checkInboxInstructions": "Te enviamos un enlace de confirmaci&oacute;n a tu correo electr&oacute;nico:",
    "continue": "Entendido",
    "resetPasswordTitle": "Restablecer contrase&ntilde;a",
    "resetInstructions": "Te enviamos un correo electr&oacute;nico, con un link para recuperar tu contrase&ntilde;a",
    "resetPassword": "Restablecer contrase&ntilde;a",
    "sendEmail": "Enviamos un correo",
    "sendTo": "Enviamos al correo electr&oacute;nico:",
    "sendInstructions": "un enlace con una hora de duraci&oacute;n para restablecer tu contrase&ntilde;a.",
    "activateTitle": "Activa ahora Harmonie",
    "activateInstructions": "Abre la App Harmonie en tu tel&eacute;fono, dir&iacute;gete a 'Mi perfil' y selecciona la opci&oacute;n 'Link con c&oacute;digo', y selecciona una opci&oacute;n para conectar.",
    "activateScan": "Escanea este QR",
    "activateCode": "Ingresa este c&oacute;digo",
    "error": "Ha ocurrido un error",
    "infoReset": "Se enviaron intrucciones para resetear la contrase&ntilde;a",
    "errorNameMessage": "Por favor, introduzca su nombre",
    "errorEmailMessage1": "Por favor, introduzca un correo electr&oacute;nico",
    "errorEmailMessage2": "Por favor, introduzca un correo electr&oacute;nico v&aacute;lido",
    "errorEmailMessage3": "Debes verificar primero tu correo electr&oacute;nico",
    "errorEmailMessage4": "Correo no est&aacute; registrado",
    "errorPasswordMessage1": "Por favor, introduzca una contrase&ntilde;a",
    "accountCreated": "Cuenta creada con &eacute;xito",
    "emailExists": "El correo ya fue registrado",
    "passwordMinLength": "La contrase&ntilde;a debe tener m&iacute;nimo 6 caracteres",
    "passwordNotMeetRequirements": "La contrase&ntilde;a debe tener m&iacute;nimo 8 caracteres, contener un caracter especial y una letra may&uacute;scula",
    "passwordInvalid": "La contrase&ntilde;a es incorrecta",
    "currentPasswordInvalid": "La contrase&ntilde;a actual es incorrecta",
    "tooManyAttempts": "Demasiados intentos. Intenta m&aacute;s tarde",
    "resetSuccess": "La contrase&ntilde;a fue cambiada con &eacute;xito",
    "resetYour": "Cambie su contrase&ntilde;a:",
    "newPassword": "Contrase&ntilde;a nueva",
    "confirmPassword": "Confirme contrase&ntilde;a",
    "changePassword": "Cambiar contrase&ntilde;a",
    "resetFailed": "Lo sentimos, la recuperaci&oacute;n de contrase&ntilde;a no fue exitosa. Contacte a soporte e int&eacute;ntelo de nuevo!",
    "resetExpiredCode": "Lo sentimos, el enlace ha expirado. Contacte a soporte e int&eacute;ntelo de nuevo!",
    "resetInvalidLink": "Lo sentimos, el enlace es inv&aacute;lido. Contacte a soporte e int&eacute;ntelo de nuevo!",
    "currentPasswordRequired": "Por favor, ingrese la contrase&ntilde;a actual",
    "newPasswordRequired": "Por favor, ingrese la nueva contrase&ntilde;a",
    "confirmPasswordRequired": "Por favor, confirme la nueva contrase&ntilde;a",
    "matchPasswordRequired": "Las contrase&ntilde;as no coinciden",
    "verificationSuccess": "La verificaci&oacute;n fue exitosa. Disfruta Harmonie!",
    "verificationFailed": "Lo sentimos, la verificaci&oacute;n fall&oacute;. Contacta a soporte e intenta de nuevo",
    "welcomeTitle": "¡Bienvenido a Harmonie!",
    "welcomeText": "Disfruta de Harmonie Life<br> desde la comodidad de tu hogar.",
    "initExperience": "Iniciar mi experiencia",
    "chooseAvatar": "Elige un avatar",
    "chooseAvatarTitle": "Elige tu avatar",
    "harmonieTV": "Harmonie TV",
    "meditation": "Meditaci&oacute;n",
    "experiences": "Experiencias",
    "avatarSuccess": "Tu avatar esta listo",
    "signupWarning1": "¿No tienes una cuenta?",
    "signupWarning2": "Regístrate desde la app móvil de Harmonie",
    "tokenExpired": "El token expiró. Por favor, inicia sesión de nuevo.",
    "sleepRelaxLive": "Duérmete. Relájate. Vive.",
    "signupGoogle": "Continuar con Google",
    "email": "Correo electrónico",
    "password2": "Contraseña",
    "charsStrength": "Al menos 8 caracteres",
    "specialStrength": "Caracter especial (@#$-/_!%*?&)",
    "shiftStrength": "Caracter en may&uacute;scula",
}



/** @module SignUp */
import $ from "jquery";

let signupModal = document.getElementById("signupModal");
let signupPanel = document.getElementById("signupPanel");
let confirmationEmail = document.getElementById("confirmationEmail");

let googleSignupButtonText = document.getElementById("googleSignupButtonText");
if(googleSignupButtonText) {
    googleSignupButtonText.textContent = lang.signupGoogle;
}

let signupNameInputText = document.getElementById("signupNameInput");
if(signupNameInputText) {
    signupNameInputText.setAttribute('placeholder', lang.name);
}

let signupEmailInputText = document.getElementById("signupEmailInput");
if(signupEmailInputText) {
    signupEmailInputText.setAttribute('placeholder', lang.email);
}

let signupPassInputText = document.getElementById("signupPassInput");
if(signupPassInputText) {
    signupPassInputText.setAttribute('placeholder', lang.password2);
}

$("#btnSignUp").click(function () {
    // $("#content-body-buttons").hide();
    // signupModal.style.display = "block";
    // $("#signupNameInput").val("").focus();
    // $("#signupEmailInput").val("");
    // $("#signupPassInput").val("");
    window.location = 'dist/signup/signup.html';
});

$("#closeModal").click(function () {
    closeModal();
});

$("#closeInboxModal").click(function () {
    closeModal();
    confirmationEmail.textContent = "";
});

/**
 * Cierra el modal de creación de cuenta
 */
function closeModal() {
    $("#content-body-buttons").show();
    $("#signupModal").fadeOut();
    $("#checkInboxModal").fadeOut();
    $("#btnApp").focus();
}

// When the user clicks anywhere outside of the modal, close it
window.onclick = function (event) {
    if (event.target == signupModal) {
        closeModal();
    }
};

$("#cancelSignupButton").click(function () {
    closeModal();
});

$("#googleSignupButton").click(function () {
    signUpGoogle();
});

$("#signupPassInput").on('input', function(e) {
    const value = e.target.value;
    const regex8chars = /^[A-Za-z\d@#$-/_!.%*?&]{8,}$/;
    $("#charsStrength").attr("opacity", regex8chars.test(value) ? "1" : "0.55");
    $("#charsStrength circle").attr("fill", regex8chars.test(value) ? "#25F708" : "white");
    const specialchar = /[@#$-/_!.%*?&]{1,}/;
    $("#specialStrength").attr("opacity",specialchar.test(value) ? "1" : "0.55");
    $("#specialStrength circle").attr("fill",specialchar.test(value) ? "#25F708" : "white");
    const shiftChar = /[A-Z]{1,}/;
    $("#shiftStrength").attr("opacity",shiftChar.test(value) ? "1" : "0.55");
    $("#shiftStrength circle").attr("fill",shiftChar.test(value) ? "#25F708" : "white");
});

$("#signupButton").click(function () {
    $("#signupNameInput").css("border-bottom-color", "#FFFFFF");
    $("#signupEmailInput").css("border-bottom-color", "#FFFFFF");
    $("#signupPassInput").css("border-bottom-color", "#FFFFFF");
    $("#errorNameMessage").hide();
    $("#errorEmailMessage").hide();
    $("#errorPasswordMessage").hide();
    const email = $("#signupEmailInput");
    const pass = $("#signupPassInput");
    const name = $("#signupNameInput");

    if(!email || email.val().trim() == '') {
        $("#errorEmailMessage").show();
        markAsInvalid("#signupEmailInput");
        $("#errorEmailMessage").html(lang.errorEmailMessage1);
        return;
    }

    if(!validateEmail(email.val().trim())) {
        $("#errorEmailMessage").show();
        markAsInvalid("#signupEmailInput");
        $("#errorEmailMessage").html(lang.errorEmailMessage2);
        return;
    }

    if(!name || name.val().trim() == '') {
        $("#errorNameMessage").show();
        markAsInvalid("#signupNameInput");
        $("#errorNameMessage").html(lang.errorNameMessage);
        return;
    }

    if(!pass || pass.val().trim() == '') {
        $("#errorPasswordMessage").show();
        markAsInvalid("#signupPassInput");
        $("#errorPasswordMessage").html(lang.errorPasswordMessage1);
        return;
    }
    const regexPass = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$-/_!%*?&])[A-Za-z\d@#$-/_!.%*?&]{8,}$/;
    if (!regexPass.test(pass.val().trim())){
        $("#errorPasswordMessage").show();
        $("#signupPassInput").css("border-bottom-color", "#EB5757");
        $("#signupPassInput").focus();
        $("#errorPasswordMessage").html(lang.passwordNotMeetRequirements);
        return;
    } 
    signUpAttempt(email, pass, name);
    confirmationEmail.textContent = email.val();
});

$("#continueButton").click(function () {
    window.location = "../login/login.html";
});

/**
 * Marcar input como inválido
 */
function markAsInvalid(selector) {
    $(selector).css("border-bottom-color", "#EB5757");
    $(selector).focus();
}

/**
 * Marcar input como válido
 */
function markAsValid(selector) {
    $(selector).css("border-bottom-color", "#FFFFFF");
}

$("#signupNameInput").on( "keydown", function notify() {
    markAsValid("#signupNameInput");
    $("#errorNameMessage").hide();
});
$("#signupEmailInput").on( "keydown", function notify() {
    markAsValid("#signupEmailInput");
    $("#errorEmailMessage").hide();
});
$("#signupPassInput").on( "keydown", function notify() {
    markAsValid("#signupPassInput");
    $("#errorPasswordMessage").hide();
});

/**
 * Acción para la tecla delete
 */
function deleteKey() {
    return;
}

$(".eye-signup").click(showHideSignUpPasswordHandler);

/**
 * Manejador del evento de click en el botón de mostrar/ocultar password
 */
function showHideSignUpPasswordHandler() {
    let passInput = document.getElementById("signupPassInput");
    let hideIcon = document.getElementById("hideIcon");
    let showIcon = document.getElementById("showIcon");

    if (passInput.type === "password") {
        passInput.type = "text";
        showIcon.style.display = "none";
        hideIcon.style.display = "block";
    } else {
        passInput.type = "password";
        showIcon.style.display = "block";
        hideIcon.style.display = "none";
    }
    // $(".eye-signup").css('transform','scale(1.2)');
    // setTimeout(() => {
    //     $(".eye-signup").css('transform','scale(1)');
    // }, 200);
}
